import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DayOfWeek } from 'src/app/model/day-of-week.model';
import { RestSalasDataSource } from 'src/app/model/rest.salas.datasource';
import { SelecionarPacienteComponent } from '../selecionar-paciente/selecionar-paciente.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-horario',
    templateUrl: './add-horario.component.html',
    styleUrls: ['./add-horario.component.scss'],
})
export class AddHorarioComponent implements OnInit {

    @Input() itensHorarios: any;
    @Input() totalVagasSala: number;
    @Input() horario: number;
    @Input() diaDaSemana: DayOfWeek;
    @Input() salaId: string;
    @Output() updateReservas = new EventEmitter();

    constructor(private modalService: NgbModal, private reservaRepository: RestSalasDataSource, private toastr: ToastrService) { }

    ngOnInit() {
    }

    horariosVagos() {
        return this.totalVagasSala - (this.itensHorarios?.length ?? 0);
    }

    abrirModalReserva() {
        const modalRef = this.modalService.open(SelecionarPacienteComponent);
        modalRef.componentInstance.reservaForm.horario = this.horario;
        modalRef.componentInstance.reservaForm.diaDaSemana = this.diaDaSemana;
        modalRef.componentInstance.reservaForm.salaId = this.salaId;

        modalRef.closed.subscribe(c => this.updateReservas.emit());
    }

    removerReserva(reservaId: string) {
        if (confirm("Tem certeza que deseja remover a reserva?")) {
            this.reservaRepository.removerReserva(reservaId).subscribe(
                () => {
                    this.toastr.success('Reserva removida')
                    this.updateReservas.emit();
                },
                () => {
                    this.toastr.error('Erro ao tentar remover reserva');
                }
            );
        }
    }

    tratarNome(nomeCompleto: string) {
        const words = nomeCompleto.split(' ');
        return `${words[0]} ${words[1].charAt(0)}.`;
    }
}